import { default as index8YQUyVxlMGMeta } from "/Volumes/Drive/bgd/Documents/live-display/admin/pages/enterprises/[enterpriseId]/index.vue?macro=true";
import { default as usersmPugohDmPHMeta } from "/Volumes/Drive/bgd/Documents/live-display/admin/pages/enterprises/[enterpriseId]/users.vue?macro=true";
import { default as _91enterpriseId_93tlHvNAqpo5Meta } from "/Volumes/Drive/bgd/Documents/live-display/admin/pages/enterprises/[enterpriseId].vue?macro=true";
import { default as indexpXc25Llia8Meta } from "/Volumes/Drive/bgd/Documents/live-display/admin/pages/enterprises/index.vue?macro=true";
import { default as enterprisesVQsLz8mdusMeta } from "/Volumes/Drive/bgd/Documents/live-display/admin/pages/enterprises.vue?macro=true";
import { default as index1Ib04dAZ8nMeta } from "/Volumes/Drive/bgd/Documents/live-display/admin/pages/index.vue?macro=true";
export default [
  {
    name: enterprisesVQsLz8mdusMeta?.name,
    path: "/enterprises",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/admin/pages/enterprises.vue").then(m => m.default || m),
    children: [
  {
    name: _91enterpriseId_93tlHvNAqpo5Meta?.name,
    path: ":enterpriseId()",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/admin/pages/enterprises/[enterpriseId].vue").then(m => m.default || m),
    children: [
  {
    name: "enterprises-enterpriseId",
    path: "",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/admin/pages/enterprises/[enterpriseId]/index.vue").then(m => m.default || m)
  },
  {
    name: "enterprises-enterpriseId-users",
    path: "users",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/admin/pages/enterprises/[enterpriseId]/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "enterprises",
    path: "",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/admin/pages/enterprises/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    redirect: "/enterprises",
    component: () => import("/Volumes/Drive/bgd/Documents/live-display/admin/pages/index.vue").then(m => m.default || m)
  }
]