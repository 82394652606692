import revive_payload_client_KdIiMyIE3d from "/Volumes/Drive/bgd/Documents/live-display/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_ioredis@5.4.1_magicast_xavluwrbio3c34ewlvbtemwsj4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_b5IDzdYdO7 from "/Volumes/Drive/bgd/Documents/live-display/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_ioredis@5.4.1_magicast_xavluwrbio3c34ewlvbtemwsj4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6pnQTfF5DT from "/Volumes/Drive/bgd/Documents/live-display/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_ioredis@5.4.1_magicast_xavluwrbio3c34ewlvbtemwsj4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ef1hzwlxjm from "/Volumes/Drive/bgd/Documents/live-display/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_ioredis@5.4.1_magicast_xavluwrbio3c34ewlvbtemwsj4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_FYWNSyGx3j from "/Volumes/Drive/bgd/Documents/live-display/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_ioredis@5.4.1_magicast_xavluwrbio3c34ewlvbtemwsj4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_NcNY7dSDqB from "/Volumes/Drive/bgd/Documents/live-display/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_ioredis@5.4.1_magicast_xavluwrbio3c34ewlvbtemwsj4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_9t5JEn3SLs from "/Volumes/Drive/bgd/Documents/live-display/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_ioredis@5.4.1_magicast_xavluwrbio3c34ewlvbtemwsj4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A2vRjUv6iQ from "/Volumes/Drive/bgd/Documents/live-display/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.18.1_typescript@5.5.4_vue@3.4.36_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Volumes/Drive/bgd/Documents/live-display/admin/.nuxt/components.plugin.mjs";
import prefetch_client_oJ8V1eC7ir from "/Volumes/Drive/bgd/Documents/live-display/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_ioredis@5.4.1_magicast_xavluwrbio3c34ewlvbtemwsj4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_TGjoyuHVh5 from "/Volumes/Drive/bgd/Documents/live-display/node_modules/.pnpm/nuxt-quasar-ui@2.1.3_@quasar+extras@1.16.12_magicast@0.3.4_quasar@2.16.8_rollup@4.18.1/node_modules/nuxt-quasar-ui/dist/runtime/plugin.js";
import airbrake_H5MbEqRsNX from "/Volumes/Drive/bgd/Documents/live-display/shared/plugins/airbrake.ts";
import firebase_MrRCoKgj2U from "/Volumes/Drive/bgd/Documents/live-display/shared/plugins/firebase.ts";
import luxon_VlqPBC7NBo from "/Volumes/Drive/bgd/Documents/live-display/shared/plugins/luxon.ts";
import route_xWBYLf55o4 from "/Volumes/Drive/bgd/Documents/live-display/shared/plugins/route.ts";
export default [
  revive_payload_client_KdIiMyIE3d,
  unhead_b5IDzdYdO7,
  router_6pnQTfF5DT,
  payload_client_ef1hzwlxjm,
  navigation_repaint_client_FYWNSyGx3j,
  check_outdated_build_client_NcNY7dSDqB,
  chunk_reload_client_9t5JEn3SLs,
  plugin_vue3_A2vRjUv6iQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_oJ8V1eC7ir,
  plugin_TGjoyuHVh5,
  airbrake_H5MbEqRsNX,
  firebase_MrRCoKgj2U,
  luxon_VlqPBC7NBo,
  route_xWBYLf55o4
]